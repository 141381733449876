<template>
  <div class="app-container">
    <div class="fs-large bold">门店设置</div>
    <el-divider />
    <div class="h s">
      <el-switch v-model="fullPay.fullPaymentShip" @change="handleFullPayChange" />
      <div class="padding-0-10">
        <div class="bold">全款发货</div>
        <div class="fc-g padding-05-0">设置是否需要消费者付完全款后才进行发货。设置完成后,请重新登录生效</div>
        <dot :type="fullPay.fullPaymentShip ? 'success' : 'gray'" same>{{fullPay.fullPaymentShip ? "已启用" : "未启用"}}</dot>
      </div>
    </div>
    <div class="h s" style="margin-top: 40px;">
      <el-switch v-model="isAutoSubmit" @change="handleIsAutoSubmitChange" />
      <div class="padding-0-10">
        <div class="bold">金准达推送DMS的零售订单是否自动提交</div>
        <dot :type="isAutoSubmit ? 'success' : 'gray'" same>{{isAutoSubmit ? "已启用" : "未启用"}}</dot>
      </div>
    </div>
    <div class="padding-20-0"></div>
    <div class="fs-large bold">库存发货设置</div>
    <el-divider />
    <div class="h">
      <el-radio-group v-model="stock.num">
        <el-radio-button label="0">不校验</el-radio-button>
        <el-radio-button label="1">强制校验</el-radio-button>
        <el-radio-button label="2">校验提醒</el-radio-button>
      </el-radio-group>&emsp;
      <el-button :loading="loading" type="success" icon="el-icon-check" @click="doSave">保存</el-button>
    </div>
    <div class="padding-10-0">
      <div class="fc-g padding-05-0">设置是否需要库存校验后才进行发货。设置完成后,请重新登录生效</div>
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";

export default {
  data() {
    return {
      fullPay: {
        fullPaymentShip: false,
      },
      isAutoSubmit: false,
      loading: false,
      stock: {
        num: 0,
      },
    };
  },
  methods: {
    loadSetting() {
      request({
        url: "api/distributor/fullPaymentShip",
        method: "get",
      }).then((res) => {
        if (null == res) res = false;
        this.fullPay.fullPaymentShip = res;
      });
    },
    handleFullPayChange() {
      request({
        url: "api/distributor/fullPaymentShip",
        method: "put",
        data: this.fullPay,
      }).then((res) => {
        this.$message.success("设置全款发货成功");
      });
    },

    loadSettingStock() {
      request({
        url: "api/distributor/deliveryStockCheck",
        method: "get",
      }).then((res) => {
        this.stock.num = res || 0;
      });
    },
    doSave() {
      this.loading = true;
      request({
        url: "api/distributor/deliveryStockCheck",
        method: "put",
        data: {
          deliveryStockCheck: this.stock.num,
        },
      })
        .then((res) => {
          this.$message.success("设置成功");
        })
        .finally((_) => {
          this.loading = false;
        });
    },
    loadIsAutoSubmit() {
      request({
        url: "api/distributor/autoSubmit",
        method: "get",
      }).then((res) => {
        this.isAutoSubmit = res;
      });
    },
    handleIsAutoSubmitChange() {
      this.loading = true;
      request({
        url: "api/distributor/autoSubmit",
        method: "put",
        data: {
          saleFormAutoSubmit: this.isAutoSubmit,
        },
      })
        .then((res) => {
          this.$message.success("设置成功");
        })
        .finally((_) => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.loadSetting();
    this.loadSettingStock();
    this.loadIsAutoSubmit();
  },
};
</script>